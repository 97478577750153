module.exports = {
  overrideViewerType: 'wysiwyg.viewer.components.inputs.ComboBoxInput',
  overrideDisplayName: 'ComboBoxInput',
  overrideComponentSkin: 'ComboBoxInputSkin',
  //
  viewerExperimentKey: 'useNewWUSDropdown',
  editorExperimentKey: 'specs.wus.useNewDropdown',
  //
  newComponentViewerType: 'wixui.Dropdown',
  newDisplayName: 'Dropdown',
  newComponentSkin: 'ComboBoxInputSkinNew'
};
